.separator-bottom svg {
    bottom: -1px;
}

.bg-gradient-info {
    background: linear-gradient(87deg, #abbf80 0, #5f7234 100%) !important;
}

.bg-default {
    background-color: #1d3b29 !important;
}

.fill-default {
    fill: #1D3B29;
}

.p-fileupload-row>div:last-child {
    text-align: unset !important;
}

.icone-lixeira {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 22px;
    /* tamanho do fundo */
    height: 22px;
    /* tamanho do fundo */
    border-radius: 50%;
    /* torna o fundo redondo */
    background-color: #333;
    /* cor do fundo */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.icone-lixeira i {
    color: #fff;
    /* cor do ícone */
    font-size: 12px;
}

.icone-lixeira:hover {
    opacity: 0.7;
}

.icone-perfil {
    left: inherit;
    right: 5px;
}
